<template>
  <div v-html="html">

  </div>
</template>

<script>
export default {
  name: "html-box",
  props: {
    html: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped>

</style>
