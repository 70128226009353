import request from '@/utils/request'

export function joinCartApi(data) {
    return request.post(
        process.env.VUE_APP_API_HOST + '/cart/join',
        data,
        {}
    )
}

export function cartListApi(data) {
    return request.post(
        process.env.VUE_APP_API_HOST + '/cart/list',
        data,
        {}
    )
}

export function deleteCartApi(data) {
    return request.post(
        process.env.VUE_APP_API_HOST + '/cart/delete',
        data,
        {}
    )
}

export function countCartApi(data) {
    return request.post(
        process.env.VUE_APP_API_HOST + '/cart/count',
        data,
        {}
    )
}


export function createOrderApi(data) {
    return request.post(
        process.env.VUE_APP_API_HOST + '/order/create',
        data,
        {}
    )
}

export function cancelOrderApi(data) {
    return request.post(
        process.env.VUE_APP_API_HOST + '/order/cancel',
        data,
        {}
    )
}

export function getOrderPayStatusApi(data) {
    return request.post(
        process.env.VUE_APP_API_HOST + '/order/pay-status',
        data,
        {}
    )
}

export function getOrderPaymentQrApi(data) {
    return request.post(
        process.env.VUE_APP_API_HOST + '/order/paymentQr',
        data,
        {}
    )
}

export function getOrderAlipayUrlApi(data) {
    return request.post(
        process.env.VUE_APP_API_HOST + '/order/alipay-url',
        data,
        {}
    )
}
