<template>
  <div v-if="load.detail" class="product">
    <safe-area>
      <div class="product-info">
        <div class="product-title">
          {{lang ==='en'?detail.nameEn:detail.name}}
        </div>
        <div class="dd">
                    <div v-if="lang ==='en'?detail.descEn:detail.desc" class="product-description">{{ lang ==='en'?detail.descEn:detail.desc }}</div>
          <img v-if="detail.image" :src="detail.image" class="product-image"/>
        </div>
        <div v-if="detail.params" class="base-params">
          <div class="base-params-left">
            <div v-for="(item,i) in detail.params" v-if="i % 2 ===0" class="base-params-item">
              <span>{{ item.name }}</span> <span>{{ item.value }}</span>
            </div>
          </div>
          <div class="base-params-right">
            <div v-for="(item,i) in detail.params" v-if="i % 2 !==0" class="base-params-item">
              <span>{{ item.name }}</span> <span>{{ item.value }}</span>
            </div>
          </div>
        </div>
        <div class="tabs">
          <el-tabs type="border-card">
            <el-tab-pane>
              <span slot="label"><i class="el-icon-s-order"></i> {{lang ==='en'?'Types':'产品规格'}}</span>

              <div class="filter">
                <div v-for="(item,i) in detail.filterParams" :key="`filter-${i}`" class="filter-item">
                  <el-select :placeholder="item.key" v-model="filter[i]" collapse-tags clearable>
                    <el-option
                        v-for="child in item.value"
                        :key="child"
                        :label="child"
                        :value="child">
                    </el-option>
                  </el-select>
                </div>
              </div>
              <el-table
                  :data="filterProduct(filter,detail.product)"
                  stripe
                  style="width: 100%">
                <el-table-column
                    :label="lang ==='en'?'SN':'编号'"
                    prop="goodsCode"
                    width="120">
                </el-table-column>

                <el-table-column
                    :label="lang ==='en'?'Stock':'货期'"
                    prop="stock"
                    width="80">
                  <template slot-scope="scope">
                    <div :style="'color:'+(scope.row.inventory > 0 ? 'green' : 'red')">{{
                        scope.row.inventory > 0 ? lang ==='en'?'in Stock':'现货' : lang ==='en'?'Request':'请咨询'
                      }}
                    </div>

                  </template>
                </el-table-column>
                <el-table-column
                    :label="lang ==='en'?'Parameter':'参数'"
                    :prop="lang ==='en'?'formatParamsEn':'formatParams'"
                    min-width="300"
                >
                </el-table-column>
                <el-table-column
                    :label="lang ==='en'?'Drawing':'图纸'"
                    width="80"
                >
                  <template slot-scope="scope">
                    <span v-if="matchFile(scope.row.goodsCode)" @click="downloadFile(scope.row.goodsCode)"><i
                        class="el-icon-download"></i></span>
                  </template>
                </el-table-column>
                <el-table-column
                    :label="lang ==='en'?'Price':'价格'"
                    prop="amount"
                >
                  <template slot-scope="scope" @click="goLogin">
<!--                        {{  isLogin ? (lang ==='en'?'$':'¥') + scope.row.marketSellPrice : lang ==='en'?'Login':'点击登录查看价格' }}-->
                          {{  (lang ==='en'?'$':'¥') + scope.row.marketSellPrice}}

                      <!--                    ￥ {{ scope.row.marketSellPrice }}-->
                  </template>
                </el-table-column>
                <el-table-column
                    label=""
                    prop="amount"
                    align="right"
                    fixed="right"
                    width="140"
                >
                  <template slot-scope="scope">
                    <el-input-number v-model="scope.row.inventory2" :max="scope.row.inventory" :min="1" size="mini"
                                     v-if="scope.row.inventory > 0"
                    ></el-input-number>
                  </template>
                </el-table-column>
                <el-table-column
                    label=""
                    prop="amount"
                    align="right"
                    fixed="right"
                    width="150"
                >
                  <template slot-scope="scope">
                    <el-button plain type="danger" @click="joinCart(detail.id,scope.row.id,scope.row.inventory2)"
                               v-if="scope.row.inventory > 0">{{ lang ==='en'?'Add to Cart':'加入购物车' }}
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
            </el-tab-pane>
            <el-tab-pane>
              <span slot="label"><i class="el-icon-document"></i> {{lang ==='en'?'Drawing':'光谱曲线'}}</span>
              <div class="files">
                <el-table
                    :data="lang ==='en'?detail.filesEn:detail.files"
                    style="width: 100%">
                  <el-table-column
                      prop="date"
                      label=""
                      width="100">
                    <template slot-scope="scope">
                      <el-image :src="scope.row.preview" style="width: 50px"/>
                    </template>
                  </el-table-column>
                  <el-table-column
                      prop="name"
                      :label="lang ==='en'?'File Name':'文件名称'"
                      width="180">
                  </el-table-column>
                  <el-table-column
                      prop="address"
                      align="right"
                      fixed="right"
                      :label="lang ==='en'?'Operate':'操作'">
                    <template slot-scope="scope">
                      <el-button size="mini" @click="download(scope.row.src)">{{ lang ==='en'?'Download':'下载' }}</el-button>
                    </template>

                  </el-table-column>
                </el-table>
              </div>
            </el-tab-pane>

          </el-tabs>
        </div>
      </div>

    </safe-area>
    <div class="float-box">
      <div class="float-box-style" @click="toPage('/user/cart')">
        <div style="margin-bottom: 5px">
          <el-badge :value="cardNum" class="item">
            <i class="el-icon-shopping-cart-2" style="font-size: 30px"></i>
          </el-badge>
        </div>
        <div>{{lang ==='en'?'Cart':'购物车'}}</div>
      </div>
      <div class="float-box-style" style="padding-top: 10px" @click="onTop">
        <div style="margin-bottom: 5px">
          <i class="el-icon-arrow-up" style="font-size: 40px"></i>
        </div>
        <div>{{lang ==='en'?'Top':'回到顶部'}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import SafeArea from "@/layout/safe-area";
import {GetProductDesignApi, GetProductDetailApi, GetProductFormatListApi, GetUserLoginAuthApi} from "@/api/product";
import HtmlBox from "@/components/static-page/html-box";
import {joinCartApi, countCartApi} from "@/api/cart";
import {getLang} from "@/utils/lang";

export default {
  name: "list",
  components: {HtmlBox, SafeArea},
  data() {
    return {
      lang: getLang(),
      id: '',
      load: {
        detail: false,
        format: false,
        design: false
      },
      detail: {},
      design: [],
      format: [],
      activeName: "product",
      filter: [],
      cardNum: 0,
      isLogin: false
    }
  },
  created() {
    this.id = this.$route.query.id
    this.getLoginAuth()
    this.countCart()
  },
  methods: {
    getLoginAuth() {
      GetUserLoginAuthApi().then(res => {
        this.isLogin = res.isLogin
        this.getProductDetail()
      })
    },
    getProductDetail() {
      GetProductDetailApi(this.id).then(res => {
        this.detail = res
        this.load.detail = true
      })
    },
    countCart() {
      countCartApi().then(res => {
        this.cardNum = res.number
      })
    },
    login() {
      window.location.href = "/user/cart"
    },
    download(src) {
      window.location.href = src
    },
    joinCart(innerId, id, number) {
      if (!number || number <= 0) {
        this.$message.error('请选择加入购物车数量');
        return
      }
      joinCartApi({innerId, id, number}).then(res => {
        this.$message.success('加入购物车成功');
        this.countCart()
      })
    },
    filterProduct(filter, product) {
      const l = this.lang
      const filters = []
      for (const k in filter) {
        if (filter[k]) {
          const key = this.detail.filterParams[k].key + ":" + filter[k]
          filters.push(key)
        }
      }
      if (filters.length === 0) {
        return product
      }
      console.log(filters)
      console.log(filters.length)
      const list = []
      for (const pv of product) {
        let n = 0
        if(l === 'en'){
          for (const fv of filters) {
            if (pv.formatParamsEn.indexOf(fv) !== -1) {
              n++
            }
          }
        }else{
          for (const fv of filters) {
            if (pv.formatParams.indexOf(fv) !== -1) {
              n++
            }
          }
        }

        if (n >= filters.length) {
          list.push(pv)
        }
      }
      return list
    },
    matchFile(id) {
      const l = this.lang
      const files = l === 'en'?this.detail.filesEn:this.detail.files
      for (const v of files) {
        if (v.name == id + ".pdf") {
          return v.src
        }
      }
      return ''
    },
    downloadFile(id) {
      window.location.href = this.matchFile(id)
    },
    toPage(url) {
      this.$router.push(url)
    },
    onTop() {
      document.documentElement.scrollTop = 0;
    },
    goLogin(){
      if(!this.isLogin){
        window.location.href = "/user/login"
      }
    }
  }
}
</script>

<style scoped>
.product {
  background: #f3f4f5;
  padding: 50px 0;
}

.product-info {
  background: #ffffff;
  padding: 30px;
}

.product-title {
  padding-left: 10px;
  border-left: 6px solid #ab1018;
  font-weight: bold;
  font-size: 1.3rem;
  color: #333333;
  margin-bottom: 10px;
}

.product-image {
  width: 100%;
  margin-top: 20px;
}

.product-description {
  color: #555555;
  font-size: 0.9rem;
  margin-top: 20px;
  line-height: 2rem;
}

.base-params {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.base-params-left {
  flex: 0 0 calc(50% - 20px);
  width: calc(50% - 20px);
}

.base-params-left .base-params-item:nth-child(odd) {
  /* 匹配奇数行 */
  background-color: #e9e9e9;
  color: #333333;
}

.base-params-left .base-params-item:nth-child(even) {
  /* 匹配偶数行 */
  background-color: #f7f5f5;
  color: #333333;
}

.base-params-right .base-params-item:nth-child(odd) {
  /* 匹配奇数行 */
  background-color: #e9e9e9;
  color: #333333;
}

.base-params-right .base-params-item:nth-child(even) {
  /* 匹配偶数行 */
  background-color: #f7f5f5;
  color: #333333;
}

.base-params-right {
  flex: 0 0 calc(50% - 20px);
  width: calc(50% - 20px);
}

.base-params-item {
  display: flex;
  justify-content: space-between;
  padding: 15px 30px;
  font-size: 0.9rem;
  color: #333333;
}

.tabs {
  padding: 20px 0;
}

.dd {
  /*display: flex;*/
  /*align-items: center;*/
  /*justify-content: space-between;*/
}

.product-image {

  height: auto;

}

.filter {
  display: flex;

}

.filter-item {
  margin-right: 10px;
  width: auto;
}

.float-box {
  position: fixed;
  top: 40%;
  right: 10px;
}

.float-box-style {
  background-color: #fff;
  width: 60px;
  height: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px 15px 10px 10px;
  font-size: 14px;
  cursor: pointer;
  margin-bottom: 5px;
  border: 2px solid #E9E9E9;
}
</style>
