<template>
  <div class="bg" v-if="load">
    <safe-area>
      <div class="title">
        {{ detail.title }}
      </div>
      <div class="rich-text">
        <div v-html="detail.content" class="rich-text-content">

        </div>
      </div>
    </safe-area>
  </div>

</template>

<script>
import SafeArea from "@/layout/safe-area";
import {GetContentDetailApi} from "@/api/content";

export default {
  name: "detail",
  components: {SafeArea},
  data() {
    return {
      detail: {},
      load: false
    }
  },
  created() {
    this.getDetail()
  },
  methods: {
    getDetail() {
      GetContentDetailApi({id: this.$route.query.id}).then(res => {
        this.load = true
        this.detail = res
      })
    }
  }
}
</script>

<style scoped>
.bg {
  background: #f3f4f5;
  padding-bottom: 20px;
}

.title {
  padding: 50px 0;
  font-size: 28px;
}

.rich-text {
  background: #ffffff;
  padding: 10px 20px;
}
.rich-text-content{
  word-break: break-all;
}

</style>
<style>
video {
  max-width: 100% !important;
}
</style>
