import request from '@/utils/request'

export function GetProductGroupByCategoryApi() {
    return request.post(
        process.env.VUE_APP_API_HOST + '/product/group',
        {},
        {}
    )
}

export function GetIndexProductGroupByCategoryApi() {
    return request.post(
        process.env.VUE_APP_API_HOST + '/product/index-group',
        {},
        {}
    )
}


export function GetIndexHotFormatApi() {
    return request.post(
        process.env.VUE_APP_API_HOST + '/product/get-index-hot-format',
        {},
        {}
    )
}


export function GetProductDetailApi(id) {
    return request.post(
        process.env.VUE_APP_API_HOST + '/product/detail',
        {id},
        {}
    )
}

export function GetProductDesignApi(id) {
    return request.post(
        process.env.VUE_APP_API_HOST + '/product/get-params-design',
        {id},
        {}
    )
}

export function GetProductFormatListApi(id) {
    return request.post(
        process.env.VUE_APP_API_HOST + '/product/get-format-list',
        {id},
        {}
    )
}

export function GetProductSearchApi(data) {
    return request.post(
        process.env.VUE_APP_API_HOST + '/product/search',
        data,
        {}
    )
}

export function GetUserLoginAuthApi(data) {
    return request.post(
        process.env.VUE_APP_API_HOST + '/user/login-auth',
        data,
        {}
    )
}
